import type { SessionType } from "@/lib/argus/service/service.ts";

import { createArgusEvent } from "@/lib/argus/client/thickClient";
import { addInitEvent, initCookieConsentListener } from "@/lib/argus/events";
import {
  addMarketingParamsEventFromReferrer,
  addMarketingParamsEventFromUrl,
} from "@/lib/argus/events/marketingParams";
import {
  addCurrentPageEvent,
  addReferrerPageEvent,
} from "@/lib/argus/events/pageView";
import { getCookie } from "@/lib/helpers/cookies";

function initRootClient(sessionType: SessionType) {
  if (sessionType === "new") {
    void addInitEvent();
    void addReferrerPageEvent();
  }

  if (sessionType === "cross-domain" || sessionType === "new") {
    const consentTypes = getCookie("solvariCookieConsent");
    if (consentTypes) {
      void createArgusEvent("cookie_consent", { consentTypes });
    }
  }
  initCookieConsentListener();

  const syncPromise = addCurrentPageEvent();
  if (
    document.referrer &&
    new URL(document.referrer).hostname === window.location.hostname
  ) {
    void addMarketingParamsEventFromReferrer();
  }
  void addMarketingParamsEventFromUrl();
  return syncPromise;
}

export { initRootClient };
